const XlsxPopulate = require('xlsx-populate');

export default function generateXLSX(json, fileName) {
  return generate(json)
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
}

function generate(data) {
  return XlsxPopulate.fromBlankAsync()
    .then((workbook) => {
      data.forEach((row, index) => {
        row.forEach((col, index2) => {
          workbook.sheet(0).row(index + 1).cell(index2 + 1).value(col)
        })
      })
      return workbook.outputAsync();
    });
}