// Search block
export default function expandFilter(htmlId, filtersBlockId) {
  const element = document.querySelector(htmlId);
  const filtersBlock = document.querySelector(filtersBlockId);
  if (!element) {
    return;
  }
  element.addEventListener("click", (events) => {
    events.preventDefault();
    filtersBlock.classList.toggle('filter-hidden');
  })
  if (window.location.href.search('scope') != -1) {
    filtersBlock.classList.remove('filter-hidden');
  }
}