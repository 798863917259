require.context('../images', true)
import '../stylesheets/application.scss'
import Rails from "@rails/ujs";
import {
  Dropdown,
  Alert
} from 'bootstrap';
import "@oddcamp/cocoon-vanilla-js";
import selectChoices from "../javascript/selectChoices";
import expandFilter from "../javascript/expandFilter";
import vikon from "../javascript/vikon";

Rails.start();

let waitReady = true;
document.onreadystatechange = function () {
  if (waitReady && (document.readyState === "interactive" || document.readyState === "complete")) {
    waitReady = false;
    initWebsiteJs();
  }
};

function initWebsiteJs() {
  try {
    selectChoicesInit();
  } catch (e) {

  }
  try {
    expandFilter('#expand-filter', '.filter');
  } catch (e) {

  }
  try {
    vikon();
  } catch (e) {

  }
  try {
    alert();
  } catch (e) {

  }
}

function alert() {
  const els = document.querySelectorAll('.alert')
  for (let el of els) {
    setTimeout(() => {
      el.querySelector('button').click()
    }, 2000)
  }
}

function cocoonAfterInserEnableSelectPicker() {
  const form = document.querySelector('#change_course');
  if (form) {
    form.addEventListener('cocoon:after-insert', (event) => {
      selectChoices('.selectpicker-professor');
    })
  }
}

function selectChoicesInit(className) {
  try {
    selectChoices('.selectpicker-professor');
  } catch (e) {

  }
  try {
    selectChoices('.selectpicker');
  } catch (e) {

  }
  try {
    cocoonAfterInserEnableSelectPicker();
  } catch (e) {

  }
}