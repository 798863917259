import Choices from "choices.js";

export default function selectChoices(className) {
  document.querySelectorAll(className).forEach(element => {
    const search = element.getAttribute('data-live-search');
    new Choices(element, {
      allowHTML: true,
      duplicateItemsAllowed: true,
      searchEnabled: search === 'true',
      renderSelectedChoices: 'no',
      loadingText: 'Загрузка...',
      noResultsText: 'Не найдено',
      noChoicesText: 'No choices to choose from',
      itemSelectText: 'Выбрать',
      shouldSort: false,
    });
  })
}