import generateXLSX from "./xlsx";

const helpText = `
1 - Дисциплина
2 - Практика
3 - Государственная итоговая аттестация
4 - Факультатив
5 - Научные исследования
6 - Квалификационный экзамен
7 - Экзамен по модулю
8 - Итоговая аттестация
9 - Модуль
10 - Самостоятельная работа
11- Промежуточная аттестация`

const firstRow = ['index', 'name', 'type']
const secondRow = ['', '', helpText]
const thridRow = ['Шифр', 'Название дисциплины (модуля), практики, ГИА', 'Тип']

export default function vikon() {
  const nicaBtns = document.querySelectorAll('.nica_btn')
  nicaBtns.forEach(btn => {
    btn.addEventListener("click", async (event) => {
      event.preventDefault();
      const btn = event.target;
      const academic_plan = btn.parentElement.parentElement.children[0].innerText;
      generateVikonXLSX(academic_plan);
    })
  })
}

async function generateVikonXLSX(academic_plan) {
  try {
    const json = await fetchJsonData(academic_plan);
    let data = [firstRow, secondRow, thridRow]
    json.forEach(t => {
      data.push([t.chipher, t.name, t.nica_code_type])
    })
    generateXLSX(data, academic_plan);
  } catch (err) {
    alert(err.message || err);
  }
}

async function fetchJsonData(academic_plan) {
  return await fetch(`/nica_xls.json?academic_plan=${academic_plan}`)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      return data
    })
}